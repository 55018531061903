import isEmpty from 'lodash-es/isEmpty'

export function getItemFromStorage<T = any>(key: string): T | null {
    const item = localStorage.getItem(key) || ''
    return !isEmpty(item) ? JSON.parse(item) : null
}

export function saveItemToStorage(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data))
}

export function removeItemFromStorage(key: string): void {
    localStorage.removeItem(key)
}
