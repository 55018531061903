import { isEmpty, isEqual, omit } from 'lodash-es'

import { useHeaderStore } from '@sunrise/components'
import { defineStore } from '@sunrise/pinia'

import { bookmarkOf } from '../../mapper/bookmarkMapper'
import { Bookmark } from '../../mapper/bookmarkMapper.interface'
import { DecoratedEstate } from '../../mapper/decoratedItemEstate.interface'
import { Status } from '../../service/realEstateService.enum'
import { getItemFromStorage, removeItemFromStorage, saveItemToStorage } from '../../utils/localStorageUtils'
import {
    BOOKMARK_STORAGE_KEY,
    BookmarkBuckets,
    BookmarkType,
    FoundBookmark,
    RestorableBookmarkBucket,
} from './bookmarkStoreModule.interface'

interface BookmarkStoreState {
    bookmarks: BookmarkBuckets
    restorableBookmarks: RestorableBookmarkBucket[]
}

export const useBookmarkStore = defineStore('bookmarkStore', {
    state: (): BookmarkStoreState => ({
        bookmarks: {
            [BookmarkType.OWN_INVENTORY]: [],
            [BookmarkType.IMMOWELT]: [],
        },
        restorableBookmarks: [],
    }),
    actions: {
        populate(bookmarksFromStorage: BookmarkBuckets) {
            this.bookmarks[BookmarkType.OWN_INVENTORY] = bookmarksFromStorage[BookmarkType.OWN_INVENTORY]
            this.bookmarks[BookmarkType.IMMOWELT] = bookmarksFromStorage[BookmarkType.IMMOWELT]
        },
        clear() {
            this.bookmarks[BookmarkType.OWN_INVENTORY] = []
            this.bookmarks[BookmarkType.IMMOWELT] = []
            clearBookmarksInLocalStorage()
            updateHeader(this.getAmount)
        },
        restoreBookmark(id: string) {
            const itemToRestore = findInRestorableBucket(id, this.restorableBookmarks)

            if (itemToRestore) {
                this.restorableBookmarks = removeFromRestorableBucket(itemToRestore.item.guid, this.restorableBookmarks)
                this.bookmarks[itemToRestore.key].splice(itemToRestore.index, 0, itemToRestore.item)

                saveBookmarksInLocalStorage(this.bookmarks)
            }

            updateHeader(this.getAmount)
        },
        addBookmark(item: DecoratedEstate) {
            const inventoryKey = determineInventoryKey(item)

            this.bookmarks[inventoryKey].push(bookmarkOf(item))

            saveBookmarksInLocalStorage(this.bookmarks)
            updateHeader(this.getAmount)
        },
        updateBookmarks(updates: DecoratedEstate[]) {
            if (isEmpty(updates)) {
                return
            }

            const itemsToUpdate = updates.reduce((acc: Record<string, DecoratedEstate>, item) => {
                acc[item.exposeeId] = item
                return acc
            }, {})

            this.bookmarks[BookmarkType.OWN_INVENTORY].forEach((bookmark) => {
                const update = itemsToUpdate[bookmark.guid]

                if (update.status === Status.ONLINE) {
                    Object.assign(bookmark, omit(bookmarkOf(update), ['commercialExpose']))
                } else {
                    bookmark.status = update.status
                }
            })

            saveBookmarksInLocalStorage(this.bookmarks)
            updateHeader(this.getAmount)
        },
        removeBookmark(item: DecoratedEstate) {
            const inventoryKey = determineInventoryKey(item)

            this.bookmarks[inventoryKey] = removeFrom(item.exposeeId, this.bookmarks[inventoryKey])

            saveBookmarksInLocalStorage(this.bookmarks)
            updateHeader(this.getAmount)
        },
        removeBookmarkById(id: string) {
            const foundItem = searchForIdInBuckets(id, this.bookmarks)

            if (!foundItem) {
                return
            }

            this.restorableBookmarks.push({
                ...foundItem,
                index: findIndex(id, this.bookmarks[foundItem.key]),
            })

            this.bookmarks[foundItem.key] = removeFrom(id, this.bookmarks[foundItem.key] as Bookmark[])

            saveBookmarksInLocalStorage(this.bookmarks)
            updateHeader(this.getAmount)
        },
        initStore() {
            const bookmarksFromStorage = getItemFromStorage<BookmarkBuckets>(BOOKMARK_STORAGE_KEY)
            if (bookmarksFromStorage) {
                this.populate(bookmarksFromStorage)
                updateHeader(this.getAmount)
            }
        },
        syncBookmarkStore() {
            const bookmarksFromStorage: BookmarkBuckets | null =
                getItemFromStorage<BookmarkBuckets>(BOOKMARK_STORAGE_KEY)
            if (bookmarksFromStorage && !isEqual(this.bookmarks, bookmarksFromStorage)) {
                this.populate(bookmarksFromStorage)
                updateHeader(this.getAmount)
            }
        },
    },
    getters: {
        getAmount(state) {
            return state.bookmarks[BookmarkType.OWN_INVENTORY].length + state.bookmarks[BookmarkType.IMMOWELT].length
        },
        getBookmarkById(state) {
            return (id: string) => searchForIdInBuckets(id, state.bookmarks)
        },
    },
})

function searchForIdInBuckets(id: string, bookmarksBuckets: BookmarkBuckets): FoundBookmark | undefined {
    let item: Bookmark | undefined = findById(id, bookmarksBuckets[BookmarkType.OWN_INVENTORY])

    if (item) {
        return {
            item,
            key: BookmarkType.OWN_INVENTORY,
        }
    }

    item = findById(id, bookmarksBuckets[BookmarkType.IMMOWELT])

    if (item) {
        return {
            item,
            key: BookmarkType.IMMOWELT,
        }
    }
}

function findInRestorableBucket(
    id: string,
    restorableBucket: RestorableBookmarkBucket[],
): RestorableBookmarkBucket | undefined {
    return restorableBucket.find((bookmark) => bookmark.item.guid === id)
}

function findById(id: string, bookmarks: Bookmark[]) {
    return bookmarks.find((item) => item.guid === id)
}

function findIndex(id: string, bookmarks: Bookmark[]) {
    return bookmarks.findIndex((item) => item.guid === id)
}

function updateHeader(amount: number) {
    useHeaderStore().$patch({
        amount,
    })
}

function determineInventoryKey(decoratedEstate: DecoratedEstate) {
    return decoratedEstate.ownInventory ? BookmarkType.OWN_INVENTORY : BookmarkType.IMMOWELT
}

function saveBookmarksInLocalStorage(bookmarks: BookmarkBuckets) {
    saveItemToStorage(BOOKMARK_STORAGE_KEY, {
        [BookmarkType.OWN_INVENTORY]: bookmarks[BookmarkType.OWN_INVENTORY],
        [BookmarkType.IMMOWELT]: bookmarks[BookmarkType.IMMOWELT],
    })
}

function clearBookmarksInLocalStorage() {
    removeItemFromStorage(BOOKMARK_STORAGE_KEY)
}

function removeFrom(id: string, bookmarks: Bookmark[]): Bookmark[] {
    return bookmarks.filter((item) => item.guid !== id)
}

function removeFromRestorableBucket(id: string, bookmarks: RestorableBookmarkBucket[]): RestorableBookmarkBucket[] {
    return bookmarks.filter((bookmark) => bookmark.item.guid !== id)
}
