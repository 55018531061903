// todo guess we dont need it anymore
export enum ContraryKey {
    Flma = 'flma',
    Primi = 'primi',
    Rooma = 'rooma',
}

export enum FilterId {
    EQUIPMENT_TYPES = 'equipment-types',
    FURNISHING_TYPES = 'furnishing-types',
    POSITION_TYPES = 'position-types',
    REAL_ESTATE_AREA_RANGES = 'real-estate-area-ranges',
    REAL_ESTATE_CATEGORIES = 'real-estate-categories',
    REAL_ESTATE_PERIMETER_RANGES = 'real-estate-perimeter-ranges',
    REAL_ESTATE_PRICE_RANGES = 'real-estate-price-ranges',
    REAL_ESTATE_ROOM_RANGES = 'real-estate-room-ranges',
}

export enum Unit {
    EURO = '€',
    KM = 'km',
    SQUARE_METERS = 'm²',
    ROOMS = 'Zimmer',
}

export enum Tag {
    DEFAULT = 'default',
    RENT = 'rent',
    BUY = 'buy',
    INDUSTRY = 'industry',
}

export enum EsrType {
    SELL = 1,
    RENT = 2,
    WAZ = 3,
}

export enum EsrKey {
    RENT = 'rent-estate',
    BUY = 'buy-estate',
}

export enum EstateTypeId {
    APARTMENT = 1,
    HOUSE = 2,
    PROPERTY = 3,
    OFFICE = 4,
    RETAIL_TRADE = 5,
    HALL = 6,
    INVESTMENT_PROPERTY = 8,
    HOSPITALITY = 10,
    AGRONOMY_SIDE = 11,
    GARAGE = 13,
    TEMPORARY_RESIDENCE = 15,
    FLAT_SHARE = 16,
    STANDARDISED_HOUSE = 17,
}

export enum EstateTypeLabel {
    APARTMENT_RENT = 'Mietwohnungen',
    APARTMENT_SELL = 'Eigentumswohnungen',
    HOUSE = 'Haus',
    PROPERTY = 'Grundstück',
    OFFICE = 'Büro-/Praxisflächen',
    RETAIL_TRADE = 'Ladenflächen',
    HALL = 'Hallen/Industrieflächen',
    INVESTMENT_PROPERTY = 'Anlageobjekt',
    HOSPITALITY = 'Gastronomie/Hotels',
    AGRONOMY_SIDE = 'Land-/Forstwirtschaft',
    GARAGE = 'Garagen/Stellplätze',
    TEMPORARY_RESIDENCE = 'Wohnen auf Zeit',
    FLAT_SHARE = 'WGs',
    STANDARDISED_HOUSE = 'Typenhaus',
}

export enum OfferType {
    SELL = 'SELL',
    RENT = 'RENT',
    WAZ = 'WAZ',
}

export enum SortCriteria {
    DISTANCE = 'distance',
    CREATE_DATE_DESC = 'createdate desc',
    PRICE = 'price',
    PRICE_DESC = 'price desc',
    LIVING_SPACE = 'wohnflaeche',
    LIVING_SPACE_DESC = 'wohnflaeche desc',
}

export enum Status {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
    EXPIRED = 'EXPIRED',
    DELETED = 'DELETED',
    LOCKED = 'LOCKED',
}

export enum PriceText {
    SELL = 'Kaufpreis',
    RENT = 'Miete',
    RENT_NET = 'Kaltmiete',
    RENT_HEATING_INCL = 'Warmmiete',
    RENT_ALL_INCL = 'Pauschalmiete',
}

export enum ImageSize {
    SLIDER_W = 1250,
    SLIDER_H = 680,
    GRID_SINGLE_W = 1100,
    GRID_SMALL_W_AND_SINGLE_H = 440,
    GRID_LARGE_W = 800,
    GRID_LARGE_H = 460,
    GRID_SMALL_H = 220,
    COMPANY_LOGO_W = 260,
    COMPANY_LOGO_H = 147,
    LIST_IMAGE_OWN = 400,
    LIST_IMAGE_PARTNER = 180,
}
