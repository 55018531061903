import { defineAsyncComponent } from 'vue'

import { initClient } from '@sunrise/client'

import { useBookmarkStore } from './ts/store/bookmarkStore/bookmarkStoreModule'

import './css/_safari-input-helper.scss'
import { initHotReload } from './hotReload'

const dynamicImports = {
    MsBookmarkList: defineAsyncComponent(() => import('./components/MsBookmarkList/MsBookmarkList.vue')),
    MsDetailPartnerIframe: defineAsyncComponent(
        () => import('./components/MsDetailPartnerIframe/MsDetailPartnerIframe.vue'),
    ),
    MsDetailPartnerWebComponent: defineAsyncComponent(
        () => import('./components/MsDetailPartnerWebComponent/MsDetailPartnerWebComponent.vue'),
    ),
    MsGlobalToastManager: defineAsyncComponent(
        () => import('./components/MsGlobalToastManager/MsGlobalToastManager.vue'),
    ),
    HydratedDetailOnline: defineAsyncComponent(
        () => import('./cmsElements/M/M_Immo_Detail/HydratedDetailOnline/HydratedDetailOnline.vue'),
    ),
    HydratedIFrame: defineAsyncComponent(
        () => import('./cmsElements/E/E_RealEstate_IFrame/HydratedRealEstateIframe/HydratedRealEstateIframe.vue'),
    ),
    HydratedMailAgentFrequencyChooser: defineAsyncComponent(
        () =>
            import(
                './cmsElements/E/E_Mail_Agent_Frequency_Chooser/HydratedMailAgentFrequencyChooser/HydratedMailAgentFrequencyChooser.vue'
            ),
    ),
    HydratedRealEstateStage: defineAsyncComponent(
        () => import('./cmsElements/M/M_RealEstate_Stage/HydratedRealEstateStage/HydratedRealEstateStage.vue'),
    ),
    MsLikeButton: defineAsyncComponent(() => import('./components/MsLikeButton/MsLikeButton.vue')),
    HydratedRealEstateValuation: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_RealEstate_Valuation/HydratedRealEstateValuation/HydratedRealEstateValuation.vue'
            ),
    ),
    MsMailAgentBanner: defineAsyncComponent(() => import('./components/MsMailAgentBanner/MsMailAgentBanner.vue')),
    HydratedMarketingNewsletterRegistration: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_Marketing_Newsletter_Registration/HydratedMarketingNewsletterRegistration/HydratedMarketingNewsletterRegistration.vue'
            ),
    ),
    HydratedRealEstateSearchList: defineAsyncComponent(
        () =>
            import(
                './cmsElements/M/M_RealEstate_SearchList/HydratedRealEstateSearchList/HydratedRealEstateSearchList.vue'
            ),
    ),
    HydratedRecommender: defineAsyncComponent(
        () => import('./cmsElements/M/M_RealEstate_Recommender/HydratedRecommender/HydratedRecommender.vue'),
    ),
}

initClient({
    adsEnabled: true,
    dynamicImports,
})

const bookmarkStore = useBookmarkStore()
bookmarkStore.initStore()
window.addEventListener('focus', () => {
    bookmarkStore.syncBookmarkStore()
})

initHotReload()
