import { Bookmark } from '../../mapper/bookmarkMapper.interface'

export const BOOKMARK_STORAGE_KEY = 'msExposes'

export enum BookmarkType {
    OWN_INVENTORY = 'own',
    IMMOWELT = 'immo',
}
export interface BookmarkStore {
    bookmarks: BookmarkBuckets
    restorableBookmarks: RestorableBookmarkBucket[]
}

export type BookmarkBuckets = {
    [BookmarkType.IMMOWELT]: Bookmark[]
    [BookmarkType.OWN_INVENTORY]: Bookmark[]
}

export type RestorableBookmarkBucket = {
    item: Bookmark
    key: BookmarkType
    index: number
}

export interface FoundBookmark {
    item: Bookmark
    key: BookmarkType
}
